<template>
  <div class="bx-header__user-navi">
    <button ref="button"
            :aria-label="ariaLabel"
            :aria-expanded="ariaExpanded"
            @click="handleClick($event, 'click')">
      <img src="/images/lecker/user-icon.svg"
           alt="Mein LECKER"
           width="32"
           height="32"
           @mouseover="handleClick($event, 'hover')">
    </button>
    <nav ref="usernavi"
         class="bx-user-navi__nav">
      <ul v-if="!isLoggedIn"
          class="bx-js-is-dropdown bx-header__navi-list bx-header__navi-list--level-1"
          @mouseleave="showUserNavi = false">
        <li>
          <a href="/registrieren"
             class="bx-typo--link">
            Konto erstellen
          </a>
        </li>
        <li>
          <a href="/anmelden"
             class="bx-typo--link">
            Anmelden
          </a>
        </li>
      </ul>
      <ul v-else
          class="bx-js-is-dropdown bx-header__navi-list bx-header__navi-list--level-1"
          @mouseleave="showUserNavi = false">
        <li>
          <a href="/meine-kochbuecher"
             class="bx-typo--link">
            Meine Kochbücher
          </a>
        </li>
        <li>
          <a href="/mein-profil"
             class="bx-typo--link">
            Mein Profil
          </a>
        </li>
        <li>
          <a class="bx-typo--link"
             href="#"
             @click="handleLogout">
            Abmelden
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'

import { useSessionStore } from '../../../stores/session'

export default {
  data () {
    return {
      showUserNavi: false
    }
  },
  computed: {
    ...mapState(useSessionStore, ['isLoggedIn']),
    ariaLabel () {
      return this.showUserNavi ? 'Navigation schließen' : 'Navigation öffnen'
    },
    ariaExpanded () {
      return this.showUserNavi ? 'true' : null
    }
  },
  watch: {
    showUserNavi () {
      if (this.showUserNavi) {
        document.body.classList.remove('bx-js-show-burger-menu')
        document.body.classList.add('bx-js-show-usernavi')
      } else {
        document.body.classList.remove('bx-js-show-usernavi')
      }
    }
  },
  created () {
    if (!import.meta.env.SSR) {
      window.addEventListener('scroll', this.handleScroll)
    }
  },
  mounted () {
    if (!import.meta.env.SSR) {
      this.observer = new MutationObserver(this.observe)

      this.observer.observe(document.body, {
        attributes: true,
        attributeFilter: ['class']
      })
      this.keepFocusWithinUserNavi()
    }
  },
  unmounted () {
    if (!import.meta.env.SSR) {
      window.removeEventListener('scroll')
    }
  },
  methods: {
    ...mapActions(useSessionStore, ['logout']),
    doLogout () {
      this.logout()
      window.location.href = '/?notify=logout-success'
    },
    observe () {
      if (document.body.classList.contains('bx-js-show-usernavi')) {
        this.showUserNavi = true
      } else {
        this.showUserNavi = false
      }
    },
    handleClick (event, eventType) {
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      if (viewportWidth < 840) {
        if (eventType === 'hover') {
          return
        }
        this.showUserNavi = !this.showUserNavi
      }
      if (viewportWidth >= 840) {
        // check if touch device event
        const isTouchDevice = event.sourceCapabilities && event.sourceCapabilities.firesTouchEvents ? event.sourceCapabilities.firesTouchEvents : (!!event.touches)
        if (isTouchDevice && eventType === 'hover') {
          // skip hover events on touch devices
          return false
        }
        this.showUserNavi = !this.showUserNavi
      }
    },
    handleScroll () {
      this.showUserNavi = false
    },
    handleLogout () {
      this.showUserNavi = false
      this.doLogout()
    },
    keepFocusWithinUserNavi () {
      const userNavi = this.$refs.usernavi
      const userNavItems = userNavi.querySelectorAll('li > a')
      const firstItem = userNavItems[0]
      const lastItem = userNavItems[userNavItems.length - 1]
      firstItem.addEventListener('keydown', e => {
        if (this.showUserNavi && e.key === 'Tab' && e.shiftKey) {
          e.preventDefault()
          lastItem.focus()
        }
      })
      lastItem.addEventListener('keydown', e => {
        if (this.showUserNavi && e.key === 'Tab' && !e.shiftKey) {
          e.preventDefault()
          firstItem.focus()
        }
      })
      userNavi.addEventListener('keyup', e => {
        if (this.showUserNavi && e.type === 'keyup' && e.key === 'Escape') {
          this.showUserNavi = false
          this.$refs.button.focus()
        }
      })
    }
  }
}
</script>
