<template>
  <section class="bx-teaser bx-typo--teaser bx-teaser--cookbook"
           :class="{ 'bx-teaser--cookbook-active': isActive }"
           data-tc="cookbook-teaser">
    <a tabindex="0"
       :data-tc="'button-'+type"
       @click="handleItemClick($event, (item?.href || item?.url || false))"
       @keyup.enter="handleItemClick($event, (item?.href || item?.url || false))">
      <Icon v-if="isActive && badgeMobile"
            :sprite="IconSprite"
            data-tc="icon-heart"
            name="heart" />
      <figure class="bx-teaser__image">
        <Icon v-if="type === 'addCollection'"
              :sprite="IconSprite"
              name="plus" />
        <picture v-else>
          <Icon v-if="!item.imageId"
                :sprite="IconSprite"
                name="logo-fork" />
          <img v-else
               :src="imageSrc(item.imageId, item.name || item.titleLong)">
          <img v-if="item.imageIdReplace"
               ref="imageReplace"
               class="bx-cookbook__image-replace">
        </picture>
        <Icon v-if="isActive && !badgeMobile"
              :sprite="IconSprite"
              data-tc="icon-heart"
              name="heart" />
      </figure>
      <div class="bx-teaser__content">
        <h2 class="bx-teaser__headline"
            data-tc="teaser-headline">
          {{ item.name || item.titleLong }}
        </h2>
        <div v-if="type === 'collections' || type === 'collectionAssets'"
             class="bx-teaser__meta">
          <span>
            <span v-if="item?.numOfAssets >= 0">
              {{ `${item.numOfAssets} Rezept${item.numOfAssets !== 1 ? 'e' : ''}` }}
            </span>
            <span v-if="item.cookingTime">
              Zubereitung <strong>{{ item.cookingTime }} Min.</strong>
            </span>
            <span v-if="item.difficultyText">
              Niveau <strong>{{ item.difficultyText }}</strong>
            </span>
          </span>

          <div ref="popup"
               class="bx-cookbook__popup"
               data-tc="list-popup"
               @click.stop="handlePopupClick()">
            <ul v-if="type === 'collections' || type === 'collectionAssets'">
              <li v-for="(menuItem, index) in popupMenu[type]"
                  :key="index"
                  :data-tc="'list-popup-' + menuItem.action"
                  tabindex="0"
                  @click.stop="handlePopupClick(menuItem.action)"
                  @keyup.enter.stop="handlePopupClick(menuItem.action)">
                <Icon :sprite="IconSprite"
                      :name="menuItem.icon" />
                {{ menuItem.text }}
              </li>
            </ul>
            <button ref="button"
                    data-tc="button-dots"
                    @click="handleDotsClick($event)"
                    @keyup.enter="handleDotsClick($event)">
              <Icon :sprite="IconSprite"
                    name="dots" />
            </button>
          </div>
        </div>
      </div>
    </a>
  </section>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../../../stores/page'
import { useConfigStore } from '../../../../stores/config'
import Icon from '../../../../components/shared/Icon.vue'
import imgSrcSet from '../../../../mixins/img-src-set.js'
import keepTabbingFocus from '../../../../mixins/keepTabbingFocus.js'

export default {
  components: {
    Icon
  },
  mixins: [imgSrcSet, keepTabbingFocus],
  props: {
    type: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    },
    badgeMobile: {
      type: Boolean,
      default: true
    }
  },
  emits: ['collection:item', 'asset:item'],
  data () {
    return {
      IconSprite: '/images/lecker/cookbook_sprite.svg',
      popupMenu: {
        collections: [
          { action: 'renameCollection', icon: 'pen', text: 'Kochbuch umbenennen' },
          { action: 'deleteCollection', icon: 'bin', text: 'Kochbuch löschen' }
        ],
        collectionAssets: [
          { action: 'moveAsset', icon: 'move', text: 'Kochbücher zum Rezept verwalten' },
          { action: 'removeAsset', icon: 'bin', text: 'Rezept aus Kochbuch entfernen' }
        ]
      }
    }
  },
  computed: {
    // Some of these store props are also or only used by mixins
    ...mapState(usePageStore, ['brandFromStore']),
    ...mapState(useConfigStore, ['rsConfig'])
  },
  mounted () {
    if (this.item.imageIdReplace) {
      this.$refs.imageReplace.onload = () => { this.$refs.imageReplace.classList.add('bx-cookbook__image-replace--show') }
      this.$refs.imageReplace.src = this.imageSrc(this.item.imageIdReplace, this.item.name || this.item.titleLong)
    }
  },
  methods: {
    imageSrc (imageId, seoName) {
      return this.createImgSrc({
        image: { imageId, seoName },
        brand: this.brandFromStore,
        width: 480,
        srcSetDefinition: {
          format: 'rectangle'
        }
      })
    },
    handleItemClick (event, url) {
      if (event.key === 'Enter' && event.target.tagName === 'BUTTON') {
        return
      }
      if (event.type !== 'click' || (event.type === 'click' && !document.body.classList.contains('bx-js-user-is-tabbing'))) {
        switch (this.type) {
          case 'collections':
            this.$emit('collection:item', { action: 'open', item: this.item })
            break
          case 'addCollection':
            this.$emit('collection:item', { action: 'add', item: {} })
            break
          case 'assetCollectionsDialog':
            this.$emit('collection:item', { action: 'toggleAsset', item: this.item })
            break
          default:
            if (url) window.open(url, '_blank')
        }
      }
    },
    handleDotsClick (event) {
      if (event.type !== 'click' || (event.type === 'click' && !document.body.classList.contains('bx-js-user-is-tabbing'))) {
        if (!this.$refs.popup.classList.contains('bx-cookbook__popup--active')) {
          this.closePopups()
          this.$refs.popup.classList.add('bx-cookbook__popup--active')
          this.keepTabbingFocus(this.$refs.popup, ['li'], () => {
            this.closePopups()
            this.removeTabbingEventListeners()
            this.$refs.button.focus()
          })
        } else {
          this.$refs.popup.classList.remove('bx-cookbook__popup--active')
        }
      }
    },
    handlePopupClick (action = false) {
      if (action) this.closePopups()
      switch (action) {
        case 'renameCollection':
          this.$emit('collection:item', { action: 'rename', item: this.item })
          break
        case 'deleteCollection':
          this.$emit('collection:item', { action: 'delete', item: this.item })
          break
        case 'moveAsset':
          this.$emit('asset:item', { action: 'move', item: this.item })
          break
        case 'removeAsset':
          this.$emit('asset:item', { action: 'remove', item: this.item })
          break
      }
    },
    closePopups () {
      Array.from(window.document.getElementsByClassName('bx-cookbook__popup--active')).forEach(el => el.classList.remove('bx-cookbook__popup--active'))
    }
  }
}
</script>
