<template>
  <div v-if="isClient"
       :data-loader="isLoading"
       data-loader-style="container">
    <div class="bx-teaser-container bx-teaser-container--one-half-width">
      <div v-if="!isLoading && itemsSubset.length === 0"
           class="bx-cookbook__empty">
        <span class="bx-typo--content-interline-h2">
          Du hast noch keine Rezepte in diesem Kochbuch.
        </span>
      </div>
      <Item v-for="(item) in itemsSubset"
            :key="item?.assetId"
            type="collectionAssets"
            :item="item"
            @asset:item="handleAssetAction($event)" />
      <Dialog v-if="showRemoveAssetOverlay"
              title="Rezept entfernen"
              class="bx-cookbook__dialog"
              data-tc="cookbook-collection-dialog"
              @close="showRemoveAssetOverlay = false">
        <template #header>
          <div class="bx-dialog__title">
            <h2>Rezept entfernen</h2>
          </div>
        </template>

        <div class="bx-content__continuous-text bx-typo--content-continuous-text bx-typo--center">
          <p>
            Möchtest du das Rezept <strong>{{ selectedAsset.titleLong }}</strong>
            wirklich aus deinem Kochbuch <strong>{{ items.name }}</strong> entfernen?
          </p>
        </div>

        <form ref="form"
              class="bx-form bx-form--pur bx-typo--form"
              @submit.prevent="removeAssetFromCollection()">
          <div class="bx-form__item bx-form__item--centered">
            <button class="bx-form__button bx-form__button--secondary"
                    @click.prevent="showRemoveAssetOverlay = false">
              Abbrechen
            </button>
            <button type="submit"
                    data-tc="dialog-submit"
                    class="bx-form__button"
                    :disabled="isLoading"
                    :data-loader="isLoading">
              Rezept entfernen
            </button>
          </div>
        </form>
      </Dialog>
      <AssetCollectionsDialog v-if="showAssetCollectionsDialog"
                              class="bx-cookbook"
                              asset-dialog-context="cookbook"
                              :asset-collections-prefetch="assetCollections"
                              :dialog-asset="selectedAsset"
                              @close:dialog="closeAssetCollectionsDialog()"
                              @asset:collection:toggled="assetCollectionToggled($event)" />
    </div>
    <PagePagination v-if="numPages > 1"
                    :page-meta="paginationPageMeta" />
  </div>
</template>

<script>
import { mapActions, mapWritableState, mapState } from 'pinia'

import { usePageStore } from '../../../../stores/page'
import { useNotifyStore } from '../../../../stores/notify'
import { useConfigStore } from '../../../../stores/config'
import { useSessionStore } from '../../../../stores/session'
import clientOnly from '../../../../mixins/client-only'
import PagePagination from '../../../../components/PagePagination.vue'
import Dialog from '../../../../components/shared/Dialog.vue'
import AssetCollections from '../../mixins/AssetCollections.js'
import CollectionsPagination from '../../mixins/CollectionsPagination.js'
import keepTabbingFocus from '../../../../mixins/keepTabbingFocus.js'
import AssetCollectionsDialog from './AssetCollectionsDialog.vue'
import Item from './Item.vue'

export default {
  components: {
    PagePagination,
    // TODO: rename dialog to something else!
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    AssetCollectionsDialog,
    Item
  },
  mixins: [AssetCollections, CollectionsPagination, keepTabbingFocus, clientOnly],
  props: {
    collectionId: {
      type: String,
      required: true
    }
  },
  emits: ['close:collection'],
  data () {
    return {
      showRemoveAssetOverlay: false,
      showAssetCollectionsDialog: false,
      selectedAsset: null
    }
  },
  computed: {
    // Some of these store props are also or only used by mixin
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapState(useSessionStore, ['token']),
    ...mapWritableState(usePageStore, ['pageData'])
  },
  watch: {
    showRemoveAssetOverlay () {
      if (!this.showRemoveAssetOverlay) this.selectedAsset = null
    },
    'items.name' (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updatePageHeader()
      }
    },
    'pageLoadError' () {
      if (this.pageLoadError) {
        this.$emit('close:collection')
      }
    }
  },
  beforeMount () {
    this.pageItems = 'collectionAssets'
    this.decrementFirstPageItems = 0
  },
  methods: {
    ...mapActions(useNotifyStore, ['showNotification']),
    updatePageHeader () {
      window.history.pushState({}, null, `/meine-kochbuecher?collection=${this.collectionId}`)
      this.pageData.body.mainStructurePath = [
        { name: 'Meine Kochbücher', url: '/meine-kochbuecher' },
        { name: this.items.name, url: null }
      ]
      this.pageData.body.headline = this.items.name
    },
    async handleAssetAction (event) {
      this.selectedAsset = event.item
      await this.getAssetCollections(this.selectedAsset.assetId)
      if (event.action === 'remove') {
        this.showRemoveAssetOverlay = true
        this.$nextTick(() => {
          this.keepTabbingFocus(this.$refs.form, ['button'], () => {
            this.removeTabbingEventListeners()
            this.showRemoveAssetOverlay = false
          })
        })
      } else {
        this.showAssetCollectionsDialog = true
      }
    },
    async removeAssetFromCollection () {
      this.isLoading = true
      try {
        await this.toggleAssetCollection(this.selectedAsset.assetId, this.collectionId)
        await this.deleteLocalItem('assetId', this.selectedAsset.assetId)
      } catch {}
      this.showRemoveAssetOverlay = false
      this.removeTabbingEventListeners()
      this.selectedAsset = null
      this.isLoading = null
    },
    async closeAssetCollectionsDialog () {
      this.showAssetCollectionsDialog = false
      this.selectedAsset = null
      this.turnPageAutoScroll = false
      this.replaceHash(this.page > 1 ? `#page=${this.page}` : '')
      await this.initPaginationCheck()
      this.$nextTick(() => {
        this.turnPageAutoScroll = true
      })
    },
    async assetCollectionToggled (event) {
      if (event.collectionId === this.collectionId) {
        if (event.action === 'remove') {
          await this.deleteLocalItem('assetId', event.assetId)
        } else {
          this.items.data = []
          this.pageLoaded = []
          await this.getItems(this.page)
        }
      }
    }
  }
}
</script>
