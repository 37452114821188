<template>
  <div class="bx-typo--content-continuous-text bx-subscription__info">
    <p>
      Alle deine Bewertungen und Kochbücher werden gelöscht.<br>
      Solltest du ein PUR-Abo haben, kannst du dieses nach dem Löschen nicht mehr nutzen.
    </p>
    <div class="bx-form__item bx-form__item--centered">
      <span v-if="errorMsg"
            class="bx-form__message bx-form__message--error">
        {{ errorMsg }}
      </span>
      <button class="bx-form__button bx-form__button--secondary"
              @click="showDoubleOptOut = true">
        Konto löschen
      </button>
    </div>
    <div v-if="showDoubleOptOut"
         class="bx-form__item bx-form__item--centered bx-typo--content-continuous-text">
      <p>
        <br>
        <strong>
          Dieser Vorgang kann nicht rückgängig gemacht werden!
          Möchtest du deine Lecker-Mitgliedschaft wirklich löschen?
        </strong>
      </p>
      <button class="bx-form__button bx-form__button--secondary"
              @click="showDoubleOptOut = false">
        Nein, Konto beibehalten
      </button>
      <button class="bx-form__button"
              :disabled="isLoading"
              :data-loader="isLoading"
              @click="deleteAccount()">
        Ja, Konto jetzt löschen
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'

import { useNotifyStore } from '../../../stores/notify'
import { useSessionStore } from '../../../stores/session'

export default {
  data () {
    return {
      showDoubleOptOut: false,
      errorMsg: false,
      isLoading: null
    }
  },
  methods: {
    ...mapActions(useNotifyStore, ['showNotification']),
    ...mapActions(useSessionStore, ['logout', 'deleteUser']),
    async deleteAccount () {
      try {
        this.isLoading = true
        await this.deleteUser()
        await this.forceLogoutAndRedirect()
      } catch (error) {
        if (error.response.status === 403) {
          this.errorMsg = 'Du hast noch ein aktives PUR-Abo, dies muss zuerst gekündigt werden, bevor du deinen Account löschen kannst.'
        } else {
          this.errorMsg = 'Wir konnten dein Konto leider nicht automatisch löschen. Bitte versuche es zu einem späteren Zeitpunkt erneut. Sollte das Problem weiter bestehen, wende dich bitte an den Kundenservice.'
        }
        this.showNotification({
          type: 'error',
          title: 'Fehler beim Löschen',
          message: 'Beim Löschen deines Kontos ist ein Fehler aufgetreten.'
        })
        this.showDoubleOptOut = false
        this.isLoading = null
      }
    },
    async forceLogoutAndRedirect () {
      this.logout()
      window.location.href = '/?notify=account-deleted'
    }
  }
}
</script>
