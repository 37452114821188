<template>
  <div class="bx-header__search bx-typo--header-search">
    <button aria-label="Suche öffnen"
            class="bx-header__search-toggle icon icon-search"
            @click="toggleSearch()"
            @keyup.enter="toggleSearch()">
      <svg><use href="/images/icon_sprite.svg#search" /></svg>
    </button>
    <form class="bx-form bx-typo--form bx-form--search-header bx-typo--form-search-header"
          onsubmit="return false;"
          @submit="submitSearch()">
      <input id="bx-header-search-input"
             v-model="searchPhrase"
             type="search"
             class="bx-form__input"
             title="Worauf hast du heute Lust?"
             placeholder="Worauf hast du heute Lust?">
      <button aria-label="Suchen"
              class="bx-header__search-submit icon icon-search">
        <svg><use href="/images/icon_sprite.svg#search" /></svg>
      </button>
      <button aria-label="Suche schliessen"
              class="bx-header__search-close icon icon-close"
              @click="toggleSearch()"
              @keyup.enter="toggleSearch()">
        <svg><use href="/images/icon_sprite.svg#close" /></svg>
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchPhrase: '',
      showSearch: false
    }
  },
  methods: {
    toggleSearch () {
      const body = document.getElementsByTagName('body')[0]
      this.showSearch = body.classList.toggle('bx-js-show-search')
      if (this.showSearch) {
        document.getElementById('bx-header-search-input').focus()
      } else {
        document.activeElement.blur()
      }
    },
    submitSearch () {
      window.location.href = `/suche?query=${encodeURIComponent(document.getElementById('bx-header-search-input').value.trim())}`
    }
  }
}
</script>
