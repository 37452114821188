<template>
  <div class="bx-content">
    <div :data-loader="isLoading"
         :class="['bx-subscription__wrapper bx-form bx-form--pur bx-typo--form', {
           'bx-subscription__wrapper--loading': isLoading
         }]">
      <ContentToggle :visible="showChangeDisplayName">
        <template #headline>
          <h3 class="bx-typo--content-interline-h3">
            Dein Anzeigename
          </h3>
        </template>
        <div class="bx-subscription__content-wrapper bx-typo--content-continuous-text">
          <p>
            Du kannst deinen Anzeigenamen jederzeit ändern.
            Der Anzeigename wird bei den von dir abgegebenen Bewertungen angezeigt.
          </p>
          <form class="bx-form bx-form--pur bx-typo--form"
                autocomplete="on"
                @submit.prevent="saveAsset">
            <div :class="['bx-form__item', {'bx-has-error': errorMsg}]">
              <label for="display_name"
                     class="bx-form__label">
                Anzeigename
              </label>
              <input id="display_name"
                     v-model="documentData.displayName"
                     name="display_name"
                     type="text"
                     placeholder="Anzeigename"
                     maxlength="40"
                     @focusout="errorMsg = null">
              <span v-if="errorMsg"
                    class="bx-form__message bx-form__message--error">
                {{ errorMsg }}
              </span>
            </div>

            <div v-if="showGlobalError"
                 class="bx-form__message bx-form__message--error">
              Der Anzeigename konnte nicht gespeichert werden. Bitte überprüfe deine Angaben.
            </div>

            <div class="bx-form__item bx-form__item--centered">
              <button type="submit"
                      class="bx-form__button"
                      :disabled="processing || preventSubmit"
                      :data-loader="processing">
                Anzeigename speichern
              </button>
            </div>
          </form>
        </div>
      </ContentToggle>

      <ContentToggle :visible="false"
                     :hide-top-border="true">
        <template #headline>
          <h3 class="bx-typo--content-interline-h3">
            Dein Passwort ändern
          </h3>
        </template>
        <div class="bx-subscription__content-wrapper">
          <ChangePassword :otp="oneTimePassword" />
        </div>
      </ContentToggle>

      <ContentToggle :visible="true"
                     :hide-top-border="true">
        <template #headline>
          <h3 class="bx-typo--content-interline-h3">
            PUR Abo
          </h3>
        </template>
        <div class="bx-form bx-form--pur bx-typo--form">
          <div class="bx-subscription__content-wrapper bx-typo--content-continuous-text">
            <p>
              Mit dem PUR-Abo hast du die Möglichkeit unser redaktionelles Angebot zu nutzen und dabei weitesgehend
              auf Werbung sowie vollständig auf Werbetracking zu verzichten.
            </p>
            <div class=" bx-form__item bx-form__item--centered">
              <button class="bx-form__button"
                      @click="goToAbo()">
                Zum PUR Abo
              </button>
            </div>
          </div>
        </div>
      </ContentToggle>

      <ContentToggle :visible="false"
                     :hide-top-border="true">
        <template #headline>
          <h3 class="bx-typo--content-interline-h3">
            Dein Konto löschen
          </h3>
        </template>
        <div class="bx-subscription__content-wrapper">
          <DeleteAccount />
        </div>
      </ContentToggle>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia'

import ContentToggle from '../../../../components/shared/ContentToggle.vue'
import ChangePassword from '../../../../components/shared/reset-password/Change.vue'
import DeleteAccount from '../DeleteAccount.vue'
import { useSessionStore } from '../../../../stores/session'
import { useNotifyStore } from '../../../../stores/notify'
import { useAssetStore } from '../../../../stores/asset'

export default {
  name: 'ParagraphTypeLeckerUserProfile',
  components: {
    ContentToggle,
    ChangePassword,
    DeleteAccount
  },
  data () {
    return {
      id: null,
      type: 'Profile',
      documentData: { displayName: '' },
      latestDisplayName: null,
      showChangeDisplayName: true,
      processing: null,
      isLoading: true,
      preventSubmit: true,
      showGlobalError: false,
      errorMsg: false,
      oneTimePassword: null
    }
  },
  computed: {
    ...mapState(useSessionStore, ['isLoggedIn', 'user']),
    defaultDisplayName () {
      return `Leckermitglied_${this.user?.userId}`
    }
  },
  watch: {
    'documentData.displayName' () {
      this.preventSubmit = this.documentData.displayName === this.latestDisplayName
    }
  },
  async mounted () {
    if (this.isLoggedIn) {
      await this.loadAsset()
      this.showChangeDisplayName = this.documentData.displayName === this.defaultDisplayName
      this.isLoading = null // vue3: data attribute will be removed (NOT if set to false)
      this.oneTimePassword = await this.getOneTimePassword()
    }
  },
  beforeMount () {
    if (!this.isLoggedIn) {
      window.location.href = '/anmelden'
    }
  },
  methods: {
    ...mapActions(useAssetStore, ['getAssetByQuery', 'patchAsset', 'postAsset']),
    ...mapActions(useSessionStore, ['getOneTimePassword', 'checkDisplayNameExists']),
    ...mapActions(useNotifyStore, ['showNotification']),
    async loadAsset () {
      const asset = await this.getAssetByQuery({
        type: this.type,
        q: `protectedMeta.creator:${this.user?.userId} AND protectedMeta.deleted:false`,
        sort: 'protectedMeta.dateCreated:asc'
      })

      if (asset) {
        this.id = asset.id
        this.documentData = asset.documentData
        this.latestDisplayName = this.documentData.displayName
      } else {
        // set fallback for manually activated user
        this.documentData.displayName = this.defaultDisplayName
      }
    },
    async saveAsset () {
      this.showGlobalError = false
      this.errorMsg = false
      this.processing = true
      try {
        await this.validateDisplayName()
        if (this.errorMsg) {
          this.showNotification({
            type: 'error',
            title: 'Fehler beim Speichern',
            message: 'Bitte überprüfe deine Eingaben.'
          })
          this.processing = null // set to null in order to remove the data-loader attribute (vue 3)
          return
        }
        if (this.id) {
          await this.patchAsset({
            id: this.id,
            type: this.type,
            asset: { documentData: this.documentData }
          })
        } else {
          const asset = await this.postAsset({
            type: this.type,
            asset: { documentData: this.documentData }
          })

          if (asset) {
            this.id = asset.id
            this.documentData = asset.documentData
            this.latestDisplayName = this.documentData.displayName
          }
        }
        this.preventSubmit = true
        this.showNotification({
          delay: 3000,
          title: 'Erfolg',
          message: 'Dein Anzeigename wurden erfolgreich gespeichert.'
        })
      } catch (e) {
        this.showGlobalError = true
      } finally {
        this.processing = null
      }
    },
    async validateDisplayName () {
      const displayNameReg = /^\p{L}+[\p{L}\p{N} ._-]*$/u
      if (this.documentData.displayName.length <= 0) {
        this.errorMsg = 'Bitte gib einen Anzeigenamen ein.'
      } else if (!this.documentData.displayName.match(displayNameReg)) {
        this.errorMsg = 'Der gewählte Anzeigename enthält ungültige Zeichen. Erlaubt sind Buchstaben, Ziffern, Leerzeichen, Punkte, Binde- und Unterstriche.'
      } else {
        const exists = await this.checkDisplayNameExists({ displayName: this.documentData.displayName })
        if (exists) {
          this.errorMsg = 'Der gewählte Anzeigename wird bereits verwendet. Bitte wählen einen anderen Anzeigenamen.'
        }
      }
    },
    goToAbo () {
      window.location.href = '/mein-abo'
    }
  }
}
</script>
