<template>
  <div class="bx-footer__abo-teaser bx-form bx-typo--form">
    <div class="bx-footer__abo-teaser-image">
      <img :src="coverUrl"
           loading="lazy"
           width="180"
           height="147"
           data-pin-nopin="true"
           alt="Cover der aktuellen Lecker Ausgabe"
           title="Cover der aktuellen Lecker Ausgabe">
    </div>
    <div class="bx-footer__abo-teaser-inner-wrapper">
      <span class="bx-footer__abo-teaser-headline">
        LECKER im Abo
      </span>
      <span class="bx-footer__abo-teaser-roofline">
        Erscheint monatlich
      </span>
      <a class="bx-form__button"
         target="_blank"
         rel="noopener"
         href="https://www.bauer-plus.de/lecker/">
        Hier geht's zum Abo
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../../stores/page'

export default {
  computed: {
    ...mapState(usePageStore, ['brandFromStore']),
    coverUrl () {
      return `${process.env.COVER_URL}${this.brandFromStore.toLowerCase()}_highres.jpg`
    }
  }
}
</script>
