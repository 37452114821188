// @ts-ignore no types here
import vueApp from '@xcel-next/rendering/src/apps/lecker/App.vue'
// @ts-ignore no types here
import { registerBrandVueComponents } from '@xcel-next/rendering/src/apps/lecker/brandVueComponents.js'

import { getMain } from '../../shared/ssr/main'

import type { SSRContext } from '@xcel-next/rendering-tools'

export function createApp (ssrContext?: SSRContext) {
  const main = getMain(vueApp, ssrContext)
  registerBrandVueComponents(main.app)
  return main
}
