<template>
  <div class="bx-content bx-recipe-search-teaser">
    <div @click="openRecipeSearch()">
      <div class="bx-content__continuous-text bx-typo--content-continuous-text bx-typo--center">
        <strong class="bx-typo--h3">Rezeptauswahl anpassen?</strong>
        <p>Filtere nach Gängen, Zubereitungszeit und vielem mehr!</p>
      </div>
      <div class="bx-recipe-search-teaser__images">
        <img :src="getImgSrc('recipe-search-teaser-filter-diets.jpg')"
             width="222"
             height="83">
        <img :src="getImgSrc('recipe-search-teaser-filter-cookingtime.jpg')"
             width="222"
             height="83">
      </div>
      <div class="bx-form bx-typo--form">
        <button class="bx-form__button">
          Rezeptauswahl verfeinern
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../../../stores/page'
import { useConfigStore } from '../../../../stores/config'

export default {
  name: 'ParagraphTypeLeckerRecipeSearchFilterTeaser',
  props: {
    // Possible prop for vueComponent but not used in this one
    // eslint-disable-next-line vue/no-unused-properties
    params: {
      type: Array,
      default: null
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(usePageStore, ['pageData']),
    ...mapState(useConfigStore, ['rsConfig'])
  },
  methods: {
    getImgSrc (name) {
      return `${(this.isPreview ? this.rsConfig.socialSettings.shareBaseUrl : '')}/images/lecker/${name}`
    },
    openRecipeSearch () {
      const { name } = this.pageData.body?.mainStructurePath?.slice(-1)[0] || {}
      window.location.href = '/suche' + (name.length > 0 ? `?query=${encodeURIComponent(name)}` : '')
    }
  }
}
</script>
