import axios from 'axios'

import { getConfig } from '../../../stores/utils/helpers'

export default {
  props: {
    itemsPerPage: {
      type: Number,
      default: 8
    },
    pageable: {
      type: Boolean,
      default: true
    },
    sortOrder: {
      type: String,
      default: null
    },
    searchPhrase: {
      String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: null,
      page: 1,
      pageLoaded: [],
      decrementFirstPageItems: 1,
      turnPageAutoScroll: true,
      pageItems: 'collections',
      pageLoadError: false,
      items: {
        data: [],
        total: 0,
        name: null
      },
      progressSearch: false,
      searchErrorMessage: '',
      endlessLoading: false
    }
  },
  watch: {
    async sortOrder () {
      this.handleSortOrderChange()
    },
    async searchPhrase () {
      this.doSearch()
    }
  },
  computed: {
    numPages () {
      return this.pageable
        ? Math.ceil((this.items.total + this.decrementFirstPageItems) / this.itemsPerPage || 1)
        : 1
    },
    itemsSubset () {
      const start = this.page === 1 ? 0 : ((this.page - 1) * this.itemsPerPage) - this.decrementFirstPageItems
      const end = this.page === 1 ? start + this.itemsPerPage - this.decrementFirstPageItems : start + this.itemsPerPage
      return this.items.data.slice(start, end).filter(obj => obj !== undefined)
    },
    paginationPageMeta () {
      return {
        numPages: this.numPages,
        page: this.page,
        useSeparator: '#',
        url: ''
      }
    }
  },
  async mounted () {
    await this.initPaginationCheck()
    this.endlessLoading = this.items.data.length === (this.itemsPerPage - this.decrementFirstPageItems)
  },
  methods: {
    async initPaginationCheck () {
      window.onhashchange = this.checkPagination
      await this.checkPagination()
    },
    async checkPagination () {
      let page = location.hash.includes('#page=') ? parseInt(location.hash.replace('#page=', '')) : 1
      page = page <= this.numPages ? page : 1
      if (!this.pageLoaded[page]) await this.getItems(page)
      if (page === 1) this.replaceHash()
      if (this.turnPageAutoScroll) {
        window.scrollTo({
          top: (document.getElementsByClassName('bx-grid-wrapper')[0].getBoundingClientRect().top + window.scrollY),
          left: 0,
          behavior: navigator.userAgent.includes('Firefox') ? 'auto' : 'smooth'
        })
      }
      this.page = page
    },
    async getItems (page = 1, item, forceIndex) {
      this.pageLoadError = false
      this.isLoading = true
      const query = {
        q: `documentData.name:${this.searchPhrase + '*'}`,
        sort: this.sortOrder
      }
      query.offset = item >= 0
        ? item
        : this.pageable
          ? (page === 1 ? 0 : ((page - 1) * this.itemsPerPage) - this.decrementFirstPageItems)
          : this.items.data.length
      query.limit = item >= 0
        ? 1
        : this.pageable
          ? (page === 1 ? this.itemsPerPage - this.decrementFirstPageItems : this.itemsPerPage)
          : this.items.data.length ? this.itemsPerPage : this.itemsPerPage - this.decrementFirstPageItems

      const params = new URLSearchParams(query)

      try {
        // rsConfig is from configStore and imported by component that uses this mixin
        const result = await axios.get(`${this.rsConfig.orchestrationServiceUrl}/collections/${this.collectionId ? this.collectionId + '/' : ''}?${params}`, getConfig(this.rsConfig.orchestrationServiceUrl, this.token))
        if (result) {
          this.items.total = result.data?.total
          this.items.name = result.data?.name
          if (this.items.data.length === 0 || this.items.data < this.items.total) {
            this.items.data = new Array(this.items.total)
          }
          if (this.pageable) {
            result.data[this.pageItems === 'collections' ? 'results' : 'assets'].forEach((el, index) => {
              const dataIndex = forceIndex >= 0 ? forceIndex : item >= 0 ? item : page === 1 ? index : ((page - 1) * this.itemsPerPage) - this.decrementFirstPageItems + index
              this.items.data[dataIndex] = el
            })
          } else {
            this.page = 1
            this.items.data = [...result.data.results]
            this.endlessLoading = this.items.data.length < result.data?.total
          }

          if (!item >= 0) this.pageLoaded[page] = true
        }
      } catch (e) {
        this.pageLoadError = true
        this.searchErrorMessage = 'Fehler bei der Suchanfrage. Bitte den Suchbegriff ändern.'
      }
      this.isLoading = null
    },
    async deleteLocalItem (property, value) {
      const deleteIndex = this.items.data.findIndex(obj => obj[property] === value)
      const preloadIndex = this.items.data.findIndex((obj, index) => index > deleteIndex && !obj)
      if (preloadIndex > 0) {
        await this.getItems(null, preloadIndex - 1, preloadIndex)
        Object.entries(this.pageLoaded).forEach(([key, val]) => {
          this.pageLoaded[key] = key > this.page ? false : val
        })
      }
      this.items.data.splice(deleteIndex, 1)
      this.items.total--
    },
    replaceHash (newHash = '') {
      history.replaceState('', '', window.location.pathname + window.location.search + newHash)
    },
    cleanupSearchPhrase (searchPhrase) {
      const cleanSearchPhrase = searchPhrase.replace(/([^a-z0-9ßäöü+ -])*/gi, '')
      if (cleanSearchPhrase !== searchPhrase) {
        this.searchErrorMessage = 'Es wurden unzulässige Zeichen aus dem Suchbegriff entfernt!'
      }
      return cleanSearchPhrase.replaceAll('+', ' ')
    },
    async doSearch () {
      this.isLoading = true
      this.searchErrorMessage = ''
      // TODO props are not allowed to be changed in the component
      // this.searchPhrase = this.cleanupSearchPhrase(this.searchPhrase)
      this.pageLoaded = []
      this.items.data = []
      this.replaceHash()
      await this.getItems()
      this.isLoading = null
    },
    async loadMoreCollections () {
      this.isLoading = true
      this.searchErrorMessage = ''
      const query = {
        sort: this.sortOrder,
        limit: this.itemsPerPage,
        offset: this.items.data.length
      }
      const params = new URLSearchParams(query)
      // rsConfig is from configStore and imported by component that uses this mixin
      const result = await axios.get(`${this.rsConfig.orchestrationServiceUrl}/collections/${this.collectionId ? this.collectionId + '/' : ''}?${params}`, getConfig(this.rsConfig.orchestrationServiceUrl, this.token))
      if (result) {
        this.items.data = [...this.items.data, ...result.data.results]
        this.endlessLoading = this.items.data.length < result.data?.total
      }
      this.$nextTick(() => {
        this.$refs.teaserContainer.scrollTop = this.$refs.teaserContainer.scrollHeight
      })
      this.isLoading = null
    },
    handleSortOrderChange () {
      this.page = 1
      this.doSearch()
    }
  }
}
