<template>
  <div class="bx-search bx-typo--search">
    <ParagraphTypeSearch
      ref="paragraphTypeSearchRef"
      brand="lecker"
      title="Suche"
      aria-label="Suche"
      :infinite-scroll="false"
      :initial-search="false"
      :filters-open="filtersOpen"
      :label-search-button="false"
      teaser-group-type="bx-teaser-container--recipe"
      label="Rezepte"
      :search="{
        fuzzy: true,
        'searchFieldPaths': [
          'documentData.headline^2',
          'documentData.paragraphs.recipe.ingredientGroups.ingredients.ingredientName'
        ],
        'assetType': 'article',
        'articleType': 'recipe',
        'filters':[
          {
            id: 'categoryfilters',
            groupName: 'categoryfilters',
            label: 'Kategorie',
            class: 'bx-filters bx-filters--categoryfilters bx-typo--filters',
            items: [{ type: 'radiogroup',
                      name: 'category',
                      fieldPath: 'documentData.paragraphs.recipe.category',
                      class: 'bx-filters__category',
                      id: 'category',
                      valueChoices: categoryItems
                    },
                    { type: 'checkbox',
                      name: 'magazinerecipe',
                      fieldPath: 'publicationMeta.usages.usage.brand',
                      class: 'bx-filters__magazine-recipe',
                      id: 'magazinerecipe',
                      label: 'Rezept aus LECKER-Heft',
                      value: 'LECKER'
                    }]
          },
          {
            id: 'morefilters',
            groupName: 'morefilters',
            class: 'bx-filters bx-filters--morefilters bx-typo--filters',
            items: [{ type: 'togglebubblechooser',
                      name: 'diet',
                      fieldPath: 'documentData.paragraphs.recipe.dietaryConsiderations',
                      class: 'bx-filters__diet',
                      id: 'diet',
                      label: 'Ernährungsart',
                      valueChoices: dietaryItems,
                      valueType: 'multichoice'
                    },
                    {type: 'range',
                     name: 'cookingtime',
                     fieldPath: 'documentData.paragraphs.recipe.cookingTime.cookingTotal',
                     class: 'bx-range__input',
                     id: 'cookingtime',
                     label:'Zubereitungszeit',
                     labelPostfix: '(in Min.)',
                     showRangeValue: true,
                     default: 125, min: 0, max: 125, stepSize: 5, stepUnit:'Minuten'
                    },
                    {type: 'range',
                     name: 'calories',
                     fieldPath: 'documentData.paragraphs.recipe.nutritionFacts.calories',
                     class: 'bx-range__input',
                     id: 'calories',
                     label:'Kalorien',
                     labelPostfix: '(in kcal)',
                     showRangeValue: true,
                     default: 1050, min: 0, max: 1050, stepSize: 50, stepUnit:'kcal'
                    }]
          }
        ],
        'orderSelect':{
          'label': 'Sortieren nach',
          'icon': 'sort',
          'options':[
            {
              id: 'bestresults',
              label: 'Beste Ergebnisse',
              order: 'desc',
              value: '',
              orderFieldPath: ''
            },
            {
              id: 'reciperating',
              label: 'Bewertung',
              order: 'desc',
              value: 'Bewertung',
              orderFieldPath: 'documentMeta.rating.value'
            },
            {
              id: 'publicationdate',
              label: 'Aktualität',
              order: 'desc',
              value: 'Aktualität',
              orderFieldPath: 'documentMeta.displayDate'
            }]
        }
      }"
      @search-phrase-changed="searchPhraseChanged">
      <template #searchandfiltertoggle>
        <div tabindex="0"
             role="button"
             :aria-label="ariaLabelFilters"
             :aria-expanded="filtersOpen"
             class="bx-filtertoggle"
             @click.prevent="toggleFilters"
             @keyup.enter="toggleFilters">
          <Icon name="filters"
                :class="filtersOpen ? 'icon--active' : 'icon--inactive'" />
          <span>{{ labelFilterToggle() }}</span>
        </div>
      </template>
      <template #filtertogglebottom>
        <div tabindex="0"
             role="button"
             @click.prevent="toggleFilters"
             @keyup.enter="toggleFilters">
          <Icon name="arrow_toggle" />
          <span>Filter schliessen</span>
        </div>
      </template>
    </ParagraphTypeSearch>
  </div>
</template>
<script>
import { ParagraphTypeSearch } from '../../../../components/paragraph-types'
import Icon from '../../../../components/shared/Icon.vue'

export default {
  components: {
    ParagraphTypeSearch, Icon
  },
  data () {
    return {
      filtersOpen: false,
      categoryItems: [
        { id: 'category_all', label: 'Alle', value: '*', default: true },
        { id: 'category_starter', label: 'Vorspeise', value: 'Vorspeise' },
        { id: 'category_mainDish', label: 'Hauptgericht', value: 'Hauptgericht' },
        { id: 'category_desert', label: 'Dessert', value: 'Dessert' },
        { id: 'category_beverage', label: 'Getränk', value: 'Getränke' },
        { id: 'category_pastry', label: 'Kuchen & Gebäck', value: 'Kuchen & Gebäck' },
        { id: 'category_snacks', label: 'Snacks & Party', value: 'Snacks & Party' }
      ],
      dietaryItems: [
        { id: 'diet_vegan', label: 'vegan', value: 'vegan' },
        { id: 'diet_vegetarian', label: 'vegetarisch', value: 'vegetarisch' },
        { id: 'diet_lactosefree', label: 'laktosefrei', value: 'laktosefrei' },
        { id: 'diet_sugarfree', label: 'zuckerfrei', value: 'zuckerfrei' },
        { id: 'diet_glutenfree', label: 'glutenfrei', value: 'glutenfrei' },
        { id: 'diet_lowcarb', label: 'low-carb', value: 'lowcarb' }
      ]
    }
  },
  computed: {
    ariaLabelFilters () {
      return this.filtersOpen ? 'Filter schliessen' : 'Filter öffnen'
    }
  },
  mounted () {
    this.filtersOpen = this.$refs.paragraphTypeSearchRef.searchPhrase.length === 0
  },
  methods: {
    toggleFilters () {
      this.filtersOpen = !this.filtersOpen
    },
    labelFilterToggle () {
      return this.filtersOpen ? 'Filter auswählen' : 'Filter anzeigen'
    },
    searchPhraseChanged (searchPhrase) {
      if (searchPhrase.length === 0) {
        this.filtersOpen = true
        this.labelFilterToggle()
      }
    }
  }
}
</script>
