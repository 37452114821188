<template>
  <div class="bx-content bx-social-share bx-typo--social-share">
    <div class="bx-social-share__element-list">
      <div class="bx-social-share__element--print">
        <div class="icon icon--social-share icon-print"
             tabindex="0"
             @click="showPrintPreview()">
          <svg><use href="/images/icon_sprite.svg#print" /></svg>
          <span class="bx-social-share__text">
            Drucken
          </span>
        </div>
      </div>

      <PinterestShareButton />

      <div class="bx-social-share__element--cookbook">
        <button class="icon icon--social-share icon-heart icon-heart--filled"
                data-tc="cookbook-button-add"
                @click.stop="addAssetToCollections()">
          <svg><use :href="`/images/lecker/cookbook_sprite.svg#heart${!assetInCollections ? '-outlined' : ''}`" /></svg>
          <span>
            {{ assetInCollections ? 'im' : 'ins' }} Kochbuch
          </span>
          <span class="bx-social-share__text">
            {{ collectionsCount }}
          </span>
        </button>
      </div>
    </div>
    <AssetCollectionsDialog v-if="showAssetToCollectionsDialog && pageData?.pageMeta?.id"
                            class="bx-cookbook"
                            asset-dialog-context="article"
                            :asset-collections-prefetch="assetCollections"
                            :dialog-asset="{
                              assetId: pageData.pageMeta.id,
                              imageId: pageData.metaData?.ogImage?.imageId || null
                            }"
                            @close:dialog="showAssetToCollectionsDialog = false"
                            @asset:collection:toggled="assetCollectionToggled($event)" />
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapActions } from 'pinia'

import { usePageStore } from '../../../../stores/page'
import { useSessionStore } from '../../../../stores/session'
import imgSrcSet from '../../../../mixins/img-src-set.js'
import urlHelper from '../../../../mixins/url-helper.js'
import AssetCollections from '../../mixins/AssetCollections.js'
import AssetCollectionsDialog from './AssetCollectionsDialog.vue'
import PinterestShareButton from '../../../../components/shared/PinterestShareButton.vue'
import { useConfigStore } from '../../../../stores/config'

export default {
  components: {
    AssetCollectionsDialog,
    PinterestShareButton
  },
  mixins: [AssetCollections, imgSrcSet, urlHelper],
  data () {
    return {
      collectionsCount: 0,
      showAssetToCollectionsDialog: false
    }
  },
  computed: {
    // Some of these store props are also or only used by mixins
    ...mapState(useSessionStore, ['isLoggedIn', 'token']),
    ...mapState(usePageStore, ['pageData', 'brandFromStore']),
    ...mapState(useConfigStore, ['rsConfig']),
    assetInCollections () {
      return this.assetCollections.length
    }
  },
  async mounted () {
    await axios.get(
      `${this.rsConfig.orchestrationServiceUrl}/collections/${this.pageData?.pageMeta?.id}/count?brand=${this.brandFromStore}`
    ).then(
      response => { this.collectionsCount = response?.data?.total || 0 }
    )
    if (this.isLoggedIn) {
      await this.getAssetCollections(this.pageData?.pageMeta?.id)
      if (this.getQueryParams()?.action === 'addToCookbook') {
        this.showAssetToCollectionsDialog = true
      }
    }
  },
  methods: {
    ...mapActions(usePageStore, ['setPrintPreview']),

    addAssetToCollections () {
      if (!this.isLoggedIn) {
        window.location.href = `/anmelden?rts=${encodeURIComponent(JSON.stringify({ action: 'addToCookbook' }))}`
      } else {
        this.showAssetToCollectionsDialog = true
      }
    },
    assetCollectionToggled (event) {
      if (event.action === 'remove') {
        this.assetCollections = this.assetCollections.filter(val => val !== event.collectionId)
        this.collectionsCount--
      } else {
        this.assetCollections.push(event.collectionId)
        this.collectionsCount++
      }
    },
    showPrintPreview () {
      document.getElementsByTagName('body')[0].classList.add('bx-page--print')
      document.scrollTop = 0
      scroll(0, 0)

      if (!import.meta.env.SSR && process.env.NODE_ENV !== 'development') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'custom_tracking_print_button'
        })
      }

      this.setPrintPreview(true)
    }
  }
}
</script>
