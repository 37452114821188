<template>
  <Dialog v-if="isClient"
          id="asset-collections-dialog"
          ref="dialog"
          :title="dialogTitle"
          class="bx-cookbook__dialog bx-cookbook__dialog--wide"
          data-tc="cookbook-recipe-dialog"
          @close="$emit('close:dialog')">
    <template #header>
      <div class="bx-dialog__title">
        <h2>{{ dialogTitle }}</h2>
      </div>
    </template>

    <NavigationBar :view="view"
                   :sort-order-list="sortOrderList"
                   :sort-order="sortOrder"
                   @close:collection="closeCollection()"
                   @change:sort:order="sortOrder = $event"
                   @change:search:phrase="searchPhrase = $event" />

    <Collections :dialog-asset="dialogAsset"
                 :asset-collections-prefetch="assetCollectionsPrefetch"
                 :sort-order="sortOrder"
                 :search-phrase="searchPhrase"
                 :items-per-page="18"
                 :pageable="false"
                 @loading:complete="handleLoadingComplete()"
                 @crud:dialog:closed="handleCrudDialogClosed()"
                 @change:sort:order="sortOrder = $event"
                 @change:sort:order:on:add="handleSortOrderChangeOnAdd"
                 @asset:collection:toggled="$emit('asset:collection:toggled', $event)"
                 @change:error:message="searchErrorMessage = $event"
                 @close:dialog="$emit('close:dialog')" />
  </Dialog>
</template>

<script>
import clientOnly from '../../../../mixins/client-only'
import Dialog from '../../../../components/shared/Dialog.vue'
import NavigationBar from '../Cookbook/NavigationBar.vue'
import Collections from './Collections.vue'
import keepTabbingFocus from '../../../../mixins/keepTabbingFocus.js'

export default {
  components: {
    NavigationBar,
    Collections,
    // TODO: rename dialog to something else!
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog
  },
  mixins: [clientOnly, keepTabbingFocus],
  props: {
    dialogAsset: {
      type: Object,
      required: true
    },
    assetCollectionsPrefetch: {
      type: Array,
      default: null
    }
  },
  emits: ['close:dialog', 'asset:collection:toggled'],
  data () {
    return {
      view: 'collections-dialog',
      sortOrder: 'documentData.name.raw:asc',
      sortOrderList: {
        'protectedMeta.dateCreated:asc': 'Erstellt: Aufsteigend',
        'protectedMeta.dateCreated:desc': 'Erstellt: Absteigend',
        'documentData.name.raw:asc': 'Name: Aufsteigend',
        'documentData.name.raw:desc': 'Name: Absteigend'
      },
      searchPhrase: '',
      setInitialFocus: true
    }
  },
  computed: {
    dialogTitle () {
      return this.assetCollectionsPrefetch.length > 0 ? 'Rezept verwalten' : 'Speichern in ...'
    }
  },
  methods: {
    keepTabbingFocusInCollectionsDialog () {
      this.$nextTick(() => {
        this.removeTabbingEventListeners()
        this.keepTabbingFocus(this.$refs.dialog.$el, ['button:not([disabled]):not([tabindex="-1"])', 'a', 'input', 'select'], 3, () => {
          if (this.dialogAsset) {
            this.removeTabbingEventListeners()
            this.$emit('close:dialog')
          }
        })
      })
    },
    handleLoadingComplete () {
      if (this.setInitialFocus) {
        this.keepTabbingFocusInCollectionsDialog()
      }
    },
    handleSortOrderChangeOnAdd () {
      this.setInitialFocus = false
      this.sortOrder = 'protectedMeta.dateCreated:desc'
    },
    handleCrudDialogClosed () {
      // we need this timeout to prevent focusable elements from crud collections dialog being added to the list of focusable elements
      setTimeout(() => {
        this.keepTabbingFocusInCollectionsDialog()
      }, 200)
    }
  }
}
</script>
