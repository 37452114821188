<template>
  <div class="bx-content bx-cookbook"
       :class="`bx-cookbook--${view}`">
    <NavigationBar :view="view"
                   :sort-order-list="sortOrderList"
                   :sort-order="sortOrder"
                   @close:collection="closeCollection()"
                   @change:sort:order="sortOrder = $event" />

    <Collections v-if="view === 'collections'"
                 :sort-order="sortOrder"
                 @open:collection="openCollection($event)"
                 @change:sort:order="sortOrder = $event"
                 @change:sort:order:on:add="handleSortOrderChangeOnAdd" />

    <CollectionAssets v-if="view === 'collectionAssets'"
                      :collection-id="openCollectionId"
                      @close:collection="closeCollection(false)" />
  </div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'

import { useConfigStore } from '../../../../stores/config'
import { useSessionStore } from '../../../../stores/session'
import { usePageStore } from '../../../../stores/page'
import adaMoltenBundle from '../../../../mixins/ada-molten-bundle'
import urlHelper from '../../../../mixins/url-helper'
import CollectionAssets from '../Cookbook/CollectionAssets.vue'
import Collections from '../Cookbook/Collections.vue'
import NavigationBar from '../Cookbook/NavigationBar.vue'

export default {
  name: 'ParagraphTypeLeckerCookbook',
  components: {
    CollectionAssets,
    Collections,
    NavigationBar
  },
  mixins: [adaMoltenBundle, urlHelper],
  data () {
    return {
      view: 'collections',
      openCollectionId: null,
      sortOrder: 'documentData.name.raw:asc',
      sortOrderList: {
        'protectedMeta.dateCreated:asc': 'Erstellt: Aufsteigend',
        'protectedMeta.dateCreated:desc': 'Erstellt: Absteigend',
        'documentData.name.raw:asc': 'Name: Aufsteigend',
        'documentData.name.raw:desc': 'Name: Absteigend'
      }
    }
  },
  computed: {
    ...mapState(useSessionStore, ['isLoggedIn']),
    ...mapState(useConfigStore, ['rsConfig']),
    ...mapWritableState(usePageStore, ['pageData']),
    reloadConfig () {
      return { ...this.rsConfig.adSlotUsage.reloadAdsOnInteraction.config, ...{ minDisplayDuration: 0, clickCount: 1 } }
    }
  },
  beforeMount () {
    if (!this.isLoggedIn) {
      window.location.href = `/anmelden?rts=${encodeURIComponent(JSON.stringify({ open: 'cookbook' }))}`
    }
  },
  mounted () {
    if (this.queryStringCollectionId()) {
      this.openCollection({ item: { id: this.queryStringCollectionId() } }, false)
    }
  },
  methods: {
    queryStringCollectionId () {
      return this.getQueryParams()?.collection ?? null
    },
    openCollection (event, reloadAds = true) {
      this.checkAdSlotReload(reloadAds)
      this.openCollectionId = event.item.id
      this.view = 'collectionAssets'
    },
    closeCollection (reloadAds = true) {
      this.checkAdSlotReload(reloadAds)
      this.openCollectionItem = null
      this.view = 'collections'
      window.history.pushState({}, null, '/meine-kochbuecher')
      this.pageData.body.mainStructurePath = [{ name: 'Meine Kochbücher', url: null }]
      this.pageData.body.headline = 'Meine Kochbücher'
    },
    checkAdSlotReload (reloadAds) {
      if (reloadAds) {
        this.adaSlotReloadOnInteraction(this.reloadConfig)
      }
    },
    handleSortOrderChangeOnAdd () {
      this.sortOrder = 'protectedMeta.dateCreated:desc'
    }
  }
}
</script>
