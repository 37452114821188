import axios from 'axios'

import { getConfig } from '../../../stores/utils/helpers'

export default {
  props: {
    assetCollectionsPrefetch: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      assetCollections: []
    }
  },
  mounted () {
    this.assetCollections = this.assetCollectionsPrefetch || []
  },
  methods: {
    async getAssetCollections (assetId) {
      await axios.get(
        // rsConfig is from configStore and imported by component that uses this mixin
        `${this.rsConfig.orchestrationServiceUrl}/collections/${assetId}/collections`,
        getConfig(this.rsConfig.orchestrationServiceUrl, this.token)
      ).then(response => {
        this.assetCollections = response?.data?.collectionIds || []
      })
    },
    async toggleAssetCollection (assetId, collectionId) {
      const action = this.assetCollections.includes(collectionId) ? 'remove' : 'add'
      try {
        await axios.post(
          `${this.rsConfig.orchestrationServiceUrl}/collections/${collectionId}/assets/${assetId}/${action}`,
          {},
          getConfig(this.rsConfig.orchestrationServiceUrl, this.token)
        )
        if (action === 'add') {
          this.assetCollections.push(collectionId)
          this.showNotification({
            delay: 3000,
            title: 'Rezept hinzugefügt',
            message: 'Das Rezept wurde erfolgreich zum Kochbuch hinzugefügt.'
          })
        } else {
          this.assetCollections = this.assetCollections.filter(item => item !== collectionId)
          this.showNotification({
            delay: 3000,
            title: 'Rezept entfernt',
            message: 'Das Rezept wurde erfolgreich aus dem Kochbuch entfernt.'
          })
        }
        this.$emit('asset:collection:toggled', { action, assetId, collectionId })
      } catch (e) {
        this.showNotification({
          type: 'error',
          delay: 3000,
          title: `Fehler beim ${action === 'add' ? 'Hinzufügen' : 'Entfernen'}`,
          message: `Beim ${action === 'add' ? 'Hinzufügen' : 'Entfernen'} des Rezepts ist leider ein Fehler aufgetreten.`
        })
      }
    }
  }
}
