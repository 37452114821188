<template>
  <div class="bx-header__logo-wrapper">
    <a class="bx-header__logo"
       title="LECKER.de"
       href="/">
      <img src="/images/lecker/lecker-logo.svg"
           alt="LECKER Logo"
           width="211"
           height="80">
    </a>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../../stores/page'
import deviceDetection from '../../../mixins/device-detection.js'

export default {
  mixins: [
    deviceDetection
  ],
  head () {
    const metaInfo = {
      script: [],
      meta: [],
      link: []
    }
    const url = `${this.pageData.pageMeta.baseUrl}${this.pageData.pageMeta.url}`
    const smartbannerMetas = [
      { name: 'smartbanner:title', content: 'Lecker - Rezepte für jeden Tag' },
      { name: 'smartbanner:author', content: 'BAUER XCEL MEDIA DEUTSCHLAND KG' },
      { name: 'smartbanner:price', content: 'FREE' },
      { name: 'smartbanner:price-suffix-google', content: ' - In Google Play' },
      { name: 'smartbanner:icon-google', content: 'https://play-lh.googleusercontent.com/kBYWLsuhl9H6LS7iOB3TQU1Hj6jgzYSZYUeH3-_15bsFmydbHP1nBRE8SOyGm23c1AE=w240-h480-rw' },
      { name: 'smartbanner:button', content: 'VIEW' },
      { name: 'smartbanner:button-url-google', content: 'http://play.google.com/store/apps/details?id=com.bauermedia.leckertagesrezepte' },
      { name: 'smartbanner:enabled-platforms', content: 'android' },
      { name: 'smartbanner:close-label', content: 'Close' }
    ]

    if (!import.meta.env.SSR) {
      const disableWhileLookingForContentShiftSolution = true
      if (!disableWhileLookingForContentShiftSolution && (this.hasMobileAds || this.isTablet)) {
        metaInfo.script.push({
          name: 'smart-app-banner',
          src: '/js/lecker/smartbanner.min.js'
        })
        metaInfo.link.push({
          rel: 'stylesheet',
          href: '/css/lecker/smartbanner.min.css',
          key: 'smartbanner'
        })
        metaInfo.meta.push(...smartbannerMetas)
      }
      metaInfo.meta.push({
        name: 'apple-itunes-app',
        content: `app-id=545648266, app-argument=${url}`
      })
    }
    return metaInfo
  },
  computed: {
    ...mapState(usePageStore, ['pageData'])
  }
}
</script>
